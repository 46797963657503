import * as React from "react";
import Api from "src/api";
import Seo from "src/components/Seo";
import UserCourses from "src/routes/School/UserCourses";

const contests: React.SFC = () => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await Api.getUserCourses();
      setData(res.data);
    };
    fetchData();
  }, []); // Перезапускать эффект только если count поменялся

  return (
    <>
      <Seo pageTitle="Курсы" />
      <UserCourses data={data} />
    </>
  );
};

export default contests;

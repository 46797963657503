import * as React from "react";
import Typography from "src/components/Typography";
import classes from "./CourseProgressBar.module.css";

interface Props {
  completePercent: number;
  red?: boolean;
}

export const CourseProgressBar: React.SFC<Props> = ({
  completePercent,
  red,
}) => {
  return null;
  if (!completePercent && completePercent !== 0) return null;
  return (
    <div className={classes.progressWrapper}>
      <Typography type="text">Пройдено {Math.round(completePercent)}%</Typography>
      <div className={classes.progressBar}>
        <div
          className={classes.progress}
          style={{ width: completePercent - 2 + "%" }}
        />
        <div className={classes.progressBG} />
        <div
          className={[classes.progressContour, red && classes.red].join(" ")}
        />
      </div>
    </div>
  );
};

export default CourseProgressBar;

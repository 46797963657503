import * as React from "react";
import Announce from "src/components/Announce/Announce";
import CourseProgressBar from "src/components/CourseProgressBar";
import Typography from "src/components/Typography";
import { CourseAnnounce } from "src/types";
import classes from "./UserCourses.module.css";

interface Props {
  data: CourseAnnounce[];
}

export const UserCourses: React.SFC<Props> = (props) => {
  return (
    <div>
      <div>
        <Typography type="h5">Ваши курсы</Typography>
      </div>
      <div className={classes.mappedAnnounces}>
        {props.data?.map((curr) => (
          <Announce
            key={curr._id}
            announce={{
              img: curr.announce.img,
              announceDescription: curr.announce?.description,
              name: curr.name,
            }}
            link={`/school/courses/${curr.latinName}`}
          >
            <div>
              <CourseProgressBar completePercent={curr.completePercent} red />
            </div>
          </Announce>
        ))}
      </div>
    </div>
  );
};

export default UserCourses;
